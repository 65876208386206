export const getBestFit = (answers: string[]) => {
  const counts: Record<string, number> = {};
  answers.forEach((answer) => {
    if (!counts[answer]) {
      counts[answer] = 0;
    }

    counts[answer]++;
  });
  const max = Math.max(...Object.values(counts));
  return Object.entries(counts)
    .filter(([, count]) => count === max)
    .pop();
};
