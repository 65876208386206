
import { CSSProperties, defineComponent } from 'vue';
import interact from 'interactjs';
import { InteractEvent } from '@interactjs/core/InteractEvent';
import throttle from 'lodash.throttle';

import { questions } from '@/questions';
import store from '@/store';

const THRESHOLD = 100;
const MAX_ROTATION = 10;
const ANIMATION_DURATION_SECS = 0.2;
const ONMOVE_THROTTLE = 15;

export default defineComponent({
  data() {
    return {
      exampleSwipe: true,
      clickAnimation: false,
      zIndex: 2,
      position: 0,
      state: 'animated' as 'animated' | 'dragged' | 'fadein' | 'fadeout',
    };
  },
  computed: {
    boxShadow(): string {
      if (this.zIndex === 2) {
        return '0 5px 30px rgba(25, 60, 92, 0.03)';
      } else {
        return 'none';
      }
    },
    question(): (typeof questions)[number] {
      return store.getters.question;
    },
    indexText(): string {
      return `${store.state.index + 1} / ${store.state.questions.length}`;
    },
    infoClass(): Record<string, boolean> {
      if (this.exampleSwipe) {
        return { exampleSwipe: true };
      }
      if (this.state === 'animated') {
        return { animated: true };
      } else if (this.state === 'fadein') {
        return { fadein: true };
      } else if (this.state === 'fadeout') {
        return { fadeout: true };
      } else {
        return {};
      }
    },
    markClass(): Record<string, boolean> {
      if (this.exampleSwipe) {
        return {};
      }
      if (this.state === 'animated') {
        return { animated: true };
      } else if (this.state === 'fadeout') {
        return { fadeout: true };
      } else {
        return {};
      }
    },
    isLeaningRight(): boolean {
      return this.position > 0;
    },
    swipeAmount(): number {
      const ratio = Math.abs(this.position / THRESHOLD);
      return Math.sign(this.position) * Math.min(1.0, ratio);
    },
    rotation(): number {
      return this.swipeAmount * MAX_ROTATION;
    },
    transformStyle(): CSSProperties {
      if (this.state === 'animated' || this.exampleSwipe) {
        return {};
      }
      const x = this.position * 0.1;
      const y = Math.abs(this.position) * 0.2;
      const rotate = this.rotation;
      const transform = `translate3D(${x}px, ${y}px, 0) rotate(${rotate}deg)`;
      const transformOrigin = this.isLeaningRight ? 'bottom right' : 'bottom left';
      return { transform, transformOrigin };
    },
    infoStyle(): CSSProperties {
      const opacity = Math.abs(this.swipeAmount);
      if (this.clickAnimation) {
        return this.animationStyle(opacity, this.position, MAX_ROTATION, ANIMATION_DURATION_SECS, this.zIndex);
      }
      const animationStyle = this.animationStyle(opacity, this.position, MAX_ROTATION, ANIMATION_DURATION_SECS, this.zIndex);
      const zIndex = this.zIndex;
      const boxShadow = this.boxShadow;
      if (this.state === 'animated' || this.state === 'fadein' || this.exampleSwipe) {
        return { zIndex, boxShadow };
      } else if (this.state === 'fadeout') {
        return { ...this.transformStyle, zIndex, boxShadow };
      }
      return {
        ...this.transformStyle,
        outlineColor: animationStyle.outlineColor,
        zIndex,
        boxShadow,
      };
    },
    markStyle(): CSSProperties {
      const opacity = Math.abs(this.swipeAmount);
      if (this.clickAnimation) {
        return this.animationStyle(opacity, this.position, MAX_ROTATION, ANIMATION_DURATION_SECS, this.zIndex);
      }
      const zIndex = this.zIndex;
      if (this.state === 'animated' || this.state === 'fadein' || this.exampleSwipe) {
        return { zIndex };
      } else if (this.state === 'fadeout') {
        return { ...this.transformStyle, zIndex };
      }
      return { ...this.transformStyle, opacity, zIndex };
    },
  },
  methods: {
    animationStyle(opacity: number, position: number, maxRotation: number, duration: number, zIndex: number): CSSProperties {
      const x = position * 0.1;
      const y = Math.abs(position) * 0.2;
      const rotation = Math.sign(position) * maxRotation;
      const transform = `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      const transformOrigin = position > 0 ? 'bottom right' : 'bottom left';
      const boxShadow = this.boxShadow;
      const transition = `${duration}s linear`;
      const transitionProperty = 'transform, transformOrigin, opacity';
      return {
        transform,
        transformOrigin,
        boxShadow,
        outlineColor: `rgba(45, 46, 55, ${opacity})`,
        transition,
        transitionProperty,
        zIndex,
      };
    },
    fadeOut() {
      this.state = 'fadeout';
      setTimeout(() => {
        this.position = 0;
        this.state = 'dragged';
        this.zIndex = 1;
        store.dispatch('confirm');
      }, 500);
    },
    fadeIn() {
      this.state = 'fadein';
      setTimeout(() => {
        this.position = 0;
        this.state = 'animated';
        this.zIndex = 2;
      }, 500);
    },
    submitAnswer() {
      const option = store.getters.options[this.isLeaningRight ? 1 : 0][1];
      store.commit('answer', option);
      // @ts-expect-error bla
      interact(this.$refs.info).unset();
      this.fadeOut();
    },
    handleClick(left: boolean) {
      store.commit('setSwipeState', left ? 'left' : 'right');
      this.state = 'dragged';
      this.position = (left ? -1 : 1) * THRESHOLD;
      this.clickAnimation = true;
      setTimeout(() => {
        store.commit('setSwipeState', 'none');
        this.clickAnimation = false;
        this.submitAnswer();
      }, ANIMATION_DURATION_SECS * 1000);
    },
    onMove(event: InteractEvent) {
      const oldPosition = this.position;
      this.position += event.dx;
      if (this.position > THRESHOLD) {
        this.position = THRESHOLD;
      }
      if (this.position < -THRESHOLD) {
        this.position = -THRESHOLD;
      }
      if (this.position === 0) {
        store.commit('setSwipeState', 'none');
      }
      if (this.position > 0 && oldPosition <= 0) {
        store.commit('setSwipeState', 'right');
      }
      if (this.position < 0 && oldPosition >= 0) {
        store.commit('setSwipeState', 'left');
      }
    },
    setupInteractions() {
      // @ts-expect-error not typed in TS, but valid in Vue3
      const interactable = interact(this.$refs.info)
        .draggable({
          inertia: false,
          onstart: () => {
            this.position = 0;
            this.state = 'dragged';
          },
          onmove: throttle(this.onMove, ONMOVE_THROTTLE),
          onend: () => {
            store.commit('setSwipeState', 'none');
            if (Math.abs(this.position) < THRESHOLD) {
              this.state = 'animated';
            } else {
              this.submitAnswer();
            }
          },
        })
        .styleCursor(false);
      store.subscribeAction((action) => {
        if (action.type === 'confirm') {
          this.fadeIn();
          // @ts-expect-error bla
          interact(this.$refs.info).set(interactable.options);
        }
      });
      store.subscribe((mutation) => {
        if (mutation.type === 'clickLeft') {
          this.handleClick(true);
        }
        if (mutation.type === 'clickRight') {
          this.handleClick(false);
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.exampleSwipe = false;
      this.setupInteractions();
    }, 2000);
  },
});
