
import { CSSProperties, defineComponent } from "vue";

import store from "@/store";

export default defineComponent({
  computed: {
    totalQuestions(): number {
      return store.state.questions.length;
    },
    index(): number {
      return store.state.index;
    },
  },
  methods: {
    backgroundColor(tabIndex: number): CSSProperties {
      if (tabIndex <= this.index) {
        return { backgroundColor: "#252727" };
      } else {
        return { backgroundColor: "#F8F8F8" };
      }
    },
  },
});
