import axios from "axios";

interface QuizPayload {
  name: string;
  email: string;
  answers: { question: string; option: string }[];
}

export default async function (payload: QuizPayload) {
  return axios.post(process.env.VUE_APP_API_ENDPOINT, payload);
}
