import { createStore } from "vuex";

import { questions } from "@/questions";
import { getBestFit } from "@/utils";

type SwipeState = "none" | "left" | "right";
type Screen = "welcome" | "card" | "finalform" | "clap";

export default createStore({
  state: {
    answers: [] as string[],
    index: 0,
    questions,
    swipeState: "none" as SwipeState,
    screen: "welcome" as Screen,
    result: "strategy" as string,
  },
  getters: {
    question: (state) => state.questions[state.index],
    options: (state) => Object.entries(state.questions[state.index].options),
    score: (state) => state.answers.filter((a) => a).length,
    answers: (state) => state.answers,
    isAllAnswered: (state) => state.answers.length === state.questions.length,
    screen: (state) => state.screen,
  },
  mutations: {
    answer(state, answer: string) {
      state.answers.push(answer);
    },
    nextQuestion(state) {
      state.index++;
    },
    setSwipeState(state, newSwipeState: SwipeState) {
      state.swipeState = newSwipeState;
    },
    start(state) {
      state.screen = "card";
      return;
    },
    finish(state) {
      state.screen = "finalform";
      const bestFit = getBestFit(state.answers);
      if (bestFit) {
        state.result = bestFit[0];
      }
      return;
    },
    submit(state) {
      state.screen = "clap";
      return;
    },
    clickLeft() {
      return;
    },
    clickRight() {
      return;
    },
  },
  actions: {
    confirm(store) {
      const { state } = store;
      if (state.index === state.questions.length - 1) {
        store.commit("finish");
      } else {
        store.commit("nextQuestion");
      }
    },
  },
  modules: {},
});
