export const scoreTitle = "Správné odpovědi:";
export const nextButton = "Další otázka";
export const lastButton = "Vyhodnocení";
export const correctAnswer = {
    isCorrect: true,
    image: require("@/assets/thumb-up.svg"),
    title: "Správná odpověď",
};
export const wrongAnswer = {
    isCorrect: false,
    image: require("@/assets/thumb-down.svg"),
    title: "Špatná odpověď",
};
export const questions = [
    {
        question:
            "Baví tě odhalovat kauzy a&nbsp;zločiny a&nbsp;u&nbsp;kriminálek víš po chvilce, kdo je vrah?",
        options: {
            Ano: "audit",
            Ne: "taxes",
        },
        image: require("@/assets/1_10.svg"),
    },
    {
        question:
            "Když se řekne „fúze“, představíš si raději zajímavou restauraci a&nbsp;chálku, nebo velkou mezinárodní transakci?",
        options: {
            Jídlo: "audit",
            Transakce: "strategy",
        },
        image: require("@/assets/2_10.svg"),
    },
    {
        question:
            "Raději hledáš řešení problému, nebo průběžně počítáš všechny možné detaily?",
        options: {
            Řešení: "consulting",
            Počty: "taxes",
        },
        image: require("@/assets/3_10.svg"),
    },
    {
        question: "Je ti povahou bližší Sherlock Holmes, nebo Tomáš Baťa?",
        options: {
            Holmes: "audit",
            Baťa: "strategy",
        },
        image: require("@/assets/4_10.svg"),
    },
    {
        question:
            "Raději rady rozdáváš, nebo si necháš poradit a&nbsp;shromažďuješ informace?",
        options: {
            Rozdávám: "consulting",
            Dostávám: "audit",
        },
        image: require("@/assets/5_10.svg"),
    },
    {
        question: "Jak se cítíš vždy na&nbsp;konci března?",
        options: {
            Natěšený: "taxes",
            "Jako v únoru": "consulting",
        },
        image: require("@/assets/6_10.svg"),
    },
    {
        question: "Miluješ AI a&nbsp;datové vědy a&nbsp;rád/a je využíváš při práci?",
        options: {
            Ano: "strategy",
            Ne: "taxes",
        },
        image: require("@/assets/7_10.svg"),
    },
    {
        question:
            "Když tví přátelé zvažují větší investici (auto, byt, nábytek…), vždy jim umíš dobře poradit.",
        options: {
            Ano: "strategy",
            Ne: "consulting",
        },
        image: require("@/assets/8_10.svg"),
    },
    {
        question:
            "Představ si, že&nbsp;nastane žraločí tornádo (Sharknado) a&nbsp;Země je v&nbsp;ohrožení. Budeš se snažit pochopit, proč se to děje a&nbsp;jak to zastavit, nebo raději spočítáš, kolik žraloků za&nbsp;minutu dopadne na&nbsp;Prahu?",
        options: {
            Pochopit: "audit",
            Spočítat: "consulting",
        },
        image: require("@/assets/9_10.svg"),
    },
    {
        question:
            "Budeš radši analyzovat data a&nbsp;odvozovat, jak nejefektivněji firmu prodat/koupit, nebo naprogramuješ systém, který to odhalí automaticky?",
        options: {
            Radit: "strategy",
            Programovat: "consulting",
        },
        image: require("@/assets/10_10.svg"),
    },
];
